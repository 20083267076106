@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.tutoring-record-container {
  padding: 2.25rem 0 0;
  font-family: Lexend, serif;

  .heading-title {
    font-weight: 600;
    font-size: 16px;
    padding-left: 12px;
  }

  .tutoring-record-table-container {
    margin-top: 26px;

    .page-size-select-container {
      margin-bottom: 15px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      .page-size-select {

        .MuiSelect-select {
          font-size: 14px;
          text-align: left;
          color: var.$color-gray;
          font-weight: 400;
          margin-left: 10px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #d9d9d9;
        }

        .MuiSelect-icon {
          color: #6c757d;
          display: flex;
        }
      }

      .page-size-description {
        font-family: Open Sans, serif;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    .table-container {
      position: relative;
      height: 100%;

      .table-header {
        display: table-header-group;
        border-radius: 0;

        .MuiTableRow-root {

          .MuiTableCell-root {
            background: var.$color-white;
            font-family: Open Sans, serif;
            font-size: 12px;
            font-weight: 700;
            color: var.$color-gray;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          font-family: Open Sans, serif;
          font-size: 12px;
          font-weight: 400;
          color: var.$color-gray;
        }
      }
    }

    .page-number-container {
      margin-top: 15px;

      .page-number-description {
        color: var.$color-gray;
        font-family: Open Sans, serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .page-number-pagination {
        .MuiPagination-ul {
          display: flex;
          gap: 7px;

          .MuiPaginationItem-root {
            border: 1px solid #E9ECEF;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: var.$color-gray;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
              background-color: #f1f1f1;
            }

            &.Mui-selected {
              background: linear-gradient(121.78deg, #11CDEF 9.06%, #1171EF 88.15%);
              color: var.$color-white;
              border: none;

              &:hover {
                background-color: #0056b3;
              }
            }
          }

          //.MuiPaginationItem-ellipsis {
          //  width: auto;
          //  height: auto;
          //  border: none;
          //  background: none;
          //  cursor: default;
          //}

          .MuiPaginationItem-icon {
            width: 25px;
            height: 25px;
            color: var.$color-gray;

            &:hover {
              color: var.$color-gray;
            }
          }
        }
      }
    }
  }

}