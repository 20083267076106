@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.modal-my-calendar-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  background-color: var.$color-white;
  border-radius: 15px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  outline: 0;
  padding: 24px;
  max-width: 400px;

  .modal-my-calendar-header {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 24px;
  }

  .modal-my-calendar-icon {
    width: 48px;
    height: 48px;
    background-color: #2576B9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .modal-my-calendar-title {
    margin: 0;
    opacity: 1;
    vertical-align: unset;
    text-decoration: none;
    font-family: Lexend, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: rgb(23, 43, 77);
    text-transform: uppercase;
    line-height: 1.37;
    @media (max-width: 1599.95px) {
      font-size: 13px;
    }
  }

  .modal-my-calendar-type {
    margin: 0;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    font-family: Lexend, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: rgb(131, 146, 171);
    line-height: 1.5;
    @media (max-width: 1599.95px) {
      font-size: 10px;
    }
  }

  .modal-text {
    margin: 0;
    font-family: Lexend, sans-serif;
    font-weight: 400;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    font-size: 14px;
    color: #172B4D;
    line-height: 2;
    @media (max-width: 1599.95px) {
      font-size: 12px;
    }
  }

  .modal-my-calendar-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1;
  }

  .modal-my-calendar-footer {
    opacity: 1;
    background: transparent;
    box-shadow: none;
    margin-top: 24px;
    color: #FFFFFF;
    font-family: Lexend, serif;
    text-transform: unset;
    width: 350px;
    max-width: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 16px;
  }

  .modal-my-calendar-button {
    display: inline-flex;
    min-width: 64px;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    background-size: 150%;
    background-position-x: 25%;
    transition: all 150ms ease-in;
    min-height: 2.5rem;
    padding: 0.625rem 1.5rem;
    margin-top: 24px;
    color: #FFFFFF;
    background-color: #2576B9;
    font-family: Lexend, serif;
    text-transform: unset;
    width: 150px;
    max-width: 50%;

    &:hover {
      background-color: #1A4F7A;
    }
  }

}