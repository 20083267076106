@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.dialog-create-or-update-link-container {

  .title-container {

    .title {
      font-family: Lexend, sans-serif;
      font-size: 20px;
      font-weight: 600;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #172B4D;
    }

  }

  .content-container {

    .label {
      font-family: Lexend, sans-serif;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #172B4D;
      margin-bottom: 5px;
    }

    .text-field {
      margin: 0;
    }

    .divider {
      margin-top: 15px;
    }
  }

  .actions-container {
    .cancel {
      font-family: Open Sans, sans-serif;
      font-size: 12px;
      font-weight: 700;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #172B4D;
      border: solid 1px #172B4D;
      //padding: 0 0 0 0;
      //margin: 0 0 0 0;
      text-transform: capitalize;
      padding: 0 32px 0 32px;

      &:hover {
        background-color: #172B4D;
        color: var.$color-white;
      }

      &:focus {
        background-color: #172B4D;
        color: var.$color-white;
      }
    }

    .submit {
      box-shadow: 0px 2px 6px 0px #00000040;
      font-family: Open Sans, sans-serif;
      font-size: 12px;
      font-weight: 700;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var.$color-white;
      background: #5E72E4;
      text-transform: capitalize;
      padding: 0 32px 0 32px;

      &:hover {
        background-color: #5E72E4;
        color: var.$color-white;
      }

      &:focus {
        background-color: #5E72E4;
        color: var.$color-white;
      }
    }
  }

}