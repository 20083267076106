@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.workshop-table-registered-container {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  .data-table-controls {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__page-size {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      &__select {
        margin-right: 0.5rem;
        width: 70px;
        background-color: var.$color-white;
        border-radius: 0.375rem;

        &__icon {
          position: absolute;
          right: 10px;
          pointer-events: none;
        }
      }

      &__label {
        margin-right: 0.5rem;
        font-size: 15px;
        font-weight: 300;
      }
    }
  }

  .workshop-table {
    border-collapse: collapse;
    font-family: "Open Sans", sans-serif;
    margin-top: 10px;

    &__header_group {
      display: table-header-group;
    }

    &__header {
      background-color: #FFFFFF;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__header-cell {
      text-align: left;
      font-size: 16px;
      cursor: pointer;
      align-items: center;
      line-height: 24px;
      color: #8392AB;
      transition: background-color 0.3s ease;
      padding: 10px 10px 10px 20px;

      &:hover {
        //background-color: #e0e0e0;
      }

      span {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    &__row {
      border-bottom: 2px dashed #ddd;

      &:last-child {
        border-bottom: none; // Xóa đường viền ở hàng cuối cùng
      }

      &__workshop_name {
        font-weight: 600;
        font-size: 16px;
        color: #344767;
      }

      &__duration {
        font-weight: 400;
        font-size: 16px;
        color: #344767;
      }

      &__description {
        font-weight: 400;
        font-size: 14px;
        color: #8392AB;
      }

      &__action {
        &__button {
          background-color: #2DCE89;
          color: var.$color-white;
          padding: 6px 32px 6px 32px;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 700;
          transition: all 0.3s ease;
          text-transform: capitalize;
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          cursor: pointer;
          display: block ruby;
          width: fit-content;

          &:hover {
            background-color: var.$color-white;
            color: #2DCE89;
          }

          &.active {
            background-color: var.$color-white;
            color: #2DCE89;
          }
        }
      }
    }

    &__row:nth-child(even) {
      background-color: #f9f9f9;
    }

    &__row-expanded {
    }

    &__cell {
      padding: 15px 15px 15px 15px;
    }

    &__expanded-row {
      border-bottom: 2px dashed #ddd;

      &:last-child {
        border-bottom: none; // Xóa đường viền ở hàng cuối cùng
      }
    }

    &__expanded-cell {

      &__duration {
        font-weight: 400;
        font-size: 16px;
        color: #344767;
      }

      &__description {
        display: flex;
        font-size: 14px;
        color: #8392AB;
      }

      &__button {
        padding: 6px 32px 6px 32px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 700;
        transition: all 0.3s ease;
        text-transform: capitalize;

        &.active {
          background-color: var.$color-white;
          color: #2DCE89;
        }

        &__register {
          cursor: pointer;
          padding: 6px 32px 6px 32px;
          text-transform: capitalize;
          background-color: #FFFDD8;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 700;
          transition: all 0.3s ease;
          color: #344767;
          box-sizing: border-box;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

          &:hover {
            background-color: #FFFDD8;
            opacity: 0.5;
            color: #344767;
          }

          &:focus {
            background-color: #FFFDD8;
            color: #344767;
          }
        }

        &__unregister {
          cursor: pointer;
          padding: 6px 32px 6px 32px;
          text-transform: capitalize;
          background-color: #FFEAEE;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 700;
          transition: all 0.3s ease;
          color: #344767;
          box-sizing: border-box;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

          &:hover {
            background-color: #FFEAEE;
            opacity: 0.5;
            color: #344767;
          }

          &:focus {
            background-color: #FFEAEE;
            color: #344767;
          }
        }

      }
    }
  }
}

@media (max-width: 768px) {
  .workshop-table__header-cell,
  .workshop-table__cell {
    padding: 8px;
    font-size: 12px;
  }

  .workshop-table__button {
    font-size: 10px;
    padding: 4px 8px;
  }
}
