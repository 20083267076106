@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.MuiPaper-root > * {
  font-family: "Lexend" !important;
}

.Toastify__toast-body > div:last-child {
  font-size: 14px;
}
#csat-trigger {
  display: none;
}

#csat-panel {
  z-index: 1000;
  width: 500px !important;
}

@media screen and (max-width: 768px) {
  #csat-panel {
    max-width: 100%;
    right: 0 !important;
  }
}

#csat-panel strong {
  font-size: 14px;
}

#csat-panel p {
  font-size: 12px;
}

#csat-panel textarea {
  font-size: 12px;
}

#csat-panel button#submit-csat {
  font-size: 13px;
  background-color: #5e72e4 !important;
  margin-top: 6px;
}

#csat-panel h5 {
  font-size: 14px;
}

#csat-panel .feedback-btn img {
  width: 62px !important;
}

#csat-panel .feedback-smiley {
  gap: 8px;
}

:root {
  --toastify-font-family: Lexend;
}

.MuiSelect-select {
  padding: 10px !important;
}
