.editor-wrapper {
  width: 100%;
}

.editor-wrapper * {
  font-family: "Lexend", "san-serif";
}

.editor-wrapper .rdw-editor-toolbar {
  border-bottom: 0;
  margin-bottom: 0;
  padding-top: 0 !important;
}

.editor-wrapper .rdw-dropdown-selectedtext {
  padding: 0;
}

.editor-wrapper .rdw-block-wrapper {
  margin-bottom: 0;
}

.editor-wrapper .custom-option {
  border: 0;
  margin: 0 !important;
}

.editor-wrapper .rdw-block-dropdown {
  width: 70px;
  font-size: 12px;
}

.editor-wrapper .custom-option .rdw-option-wrapper {
  padding: 0;
  margin: 0;
  border: 0;
}

.editor-wrapper .rdw-editor-main {
  font-size: 13px;
  padding: 8px;
}

.editor-wrapper .public-DraftStyleDefault-block {
  padding: 0px !important;
}
.editor-wrapper .rdw-image-wrapper {
  margin-bottom: 0;
}

.editor-wrapper .rdw-image-modal {
  /* Your custom positioning styles */
  top: 0px;
  left: 5px;
  transform: translateY(-100%);
}
