#table-content th,
#table-content td {
  text-align: center;
  vertical-align: middle;
}
#table-content thead,
#table-content tbody tr {
  height: 66px;
}

@media only screen and (max-width: 1599px) {
  #table-content thead,
  #table-content tbody tr {
    height: 50px;
  }
}

#table-content thead {
  font-weight: 700;
  position: sticky;
  top: -1px;
  left: -1px;
  background: white !important;
  z-index: 99;
}

@media only screen and (max-width: 1599px) {
  #table-content thead {
    font-size: 10px;
  }
}

#table-content tbody tr {
  font-weight: 500;
  border-top: 1px solid #f5f6f6;
  border-bottom: 1px solid #f5f6f6;
}

@media only screen and (max-width: 1599px) {
  #table-content tbody tr {
    font-size: 11px;
  }
}
