@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.pin-input-card-container {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 398px;
  margin: 5% auto;

  img {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  h2 {
    margin: auto;
    font-weight: 700;
    font-size: 32px;
    color: #2D3748;
  }

  .description {
    font-weight: 400;
    color: var.$color-gray;
    font-size: 14px;
    text-align: center;
  }

  .pin-input {
    display: flex;
    gap: 10px;
    margin: auto;

    &__box {
      width: 50px;
      height: 50px;
      font-size: 20px;
      text-align: center;
      border: 2px solid #ccc;
      border-radius: 5px;
      outline: none;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      &::placeholder {
        color: #ddd;
      }
    }
  }

  .error {
    margin-top: 0;
    font-weight: 300;
    font-family: Lexend, sans-serif;
    font-size: 14px;
    color: var.$color-error;
    text-align: center;

    a {
      font-weight: 700;
      text-decoration: underline !important;
    }

    a:hover {
      cursor: pointer;
      text-decoration: underline !important;
    }
  }

  .forgot-pin {
    margin: auto;
    font-size: 14px;
    font-weight: 400;
    color: #8392AB;
  }

  .forgot-pin a {
    font-weight: 700;
    text-decoration: underline !important;
  }

  .forgot-pin a:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}

