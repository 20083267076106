@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;


.my-development-container {
  position: relative;
  top: func.pxToRem(-20);
  margin: 0 auto;
  background: var.$color-white;

  @media (max-width: var.$breakpoints-md) {
    width: calc(100% - 40px);
  }

  @media (min-width: var.$breakpoints-md) {
    width: calc(100% - 80px);
  }

  @media (min-width: var.$breakpoints-xl) {
    width: calc(100% - 100px);
  }

  @media (min-width: var.$breakpoints-xxl1200) {
    width: calc(100% - 120px);
  }

  @media (min-width: var.$breakpoints-xxl1366) {
    width: calc(100% - 150px);
  }

  &__card_header.MuiBox-root {
    background: rgb(255, 255, 255);
  }

  &__card_header {
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    opacity: 1;
    color: rgb(52, 71, 103);
    box-shadow: none;
    border-radius: 1rem;

    padding: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: var.$breakpoints-md) {
      row-gap: 16px;
    }

    @media (max-width: var.$breakpoints-xxl1366) {
      flex-direction: column;
    }

    @media (max-width: var.$breakpoints-xxl1600) {
      row-gap: 20px;
      border-radius: 0.5rem;
    }

    &__tabs {
      box-sizing: border-box;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      -moz-box-align: center;
      align-items: center;
      border-radius: 0.75rem;
      padding: 0.25rem;
      background: rgb(246, 249, 252);
      max-width: 420px
    }
  }

  .data-table-full {
    .data-table-container {
      overflow: hidden;

      .data-table {
        table-layout: fixed;
        width: 100%;

        td {
          word-wrap: break-word;
          white-space: normal;
          word-break: break-word;
          overflow-wrap: anywhere;
        }

        .data-table-row {
          font-family: "Open Sans", serif;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &__cell {
            &__workshopName {
              color: var.$color-black;
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
            }

            &__description {
              color: #8392AB;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
            }

            &__action {
              display: flex;
              justify-content: center;

              &__button {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                cursor: pointer;
                background: #2DCE89;
                padding: 5px 15px 5px 15px;
                border-radius: 5px;
                color: var.$color-white;
              }
            }
          }
        }
      }
    }
  }
}
