@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.tag-status-pin {
  font-size: 12px;
  font-weight: 700;
  color: var.$color-white;
  text-transform: uppercase;
  margin-left: auto;

  @media (max-width: 1278px) {
    margin: auto;
  }

}

.tag-status-pin-unabled {
  padding: 5px 20px;
  border-radius: 20px;
  background: linear-gradient(338.54deg, #F75C3A 31.89%, #F53B58 62.81%);
}

.tag-status-pin-enabled {
  border-radius: 20px;
  padding: 5px 20px;
  background: linear-gradient(338.08deg, #2DCEC6 27.53%, #2DCE94 61.45%);
}