@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.pin-setup-form {
  .pin-setup-field {
    margin-bottom: 15px;

    .pin-setup-label {
      display: block;
      font-weight: 700;
      padding: 5px;
      font-size: 14px;
    }

    .pin-setup-input {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;
      font-size: 14px;
      max-width: 500px;
      color: var.$color-black;
    }
  }

  .pin-setup-error {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #FFA2A2;
    font-size: 12px;
    margin: auto 15px;
  }

  .pin-setup-success {
    color: green;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .pin-setup-button {
    font-family: Open Sans, sans-serif;
    padding: 6px 32px;
    background-color: #5E72E4;
    font-weight: 700;
    text-transform: uppercase;
    color: var.$color-white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    display: flex;

    .spinner-icon{
        margin: 5px;
    }
  }
}