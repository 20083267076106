@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.your-pin-code-container {

  .card-profile-header {
    margin: 25px 0;
  }

  .pin-card-container {
    font-family: Lexend, sans-serif;
    padding: 1.5rem;

    .pin-card-title {
      color: #172B4D;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .pin-card-description {
      color: var.$color-gray;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .pin-card-box {
      background-color: #1576BC;
      padding: 20px;
      border-radius: 8px;
      color: #fff;
      text-align: left;

      .pin-card-subtitle {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .pin-card-info {
        font-family: Open Sans, sans-serif;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }

}