@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.rs-picker-check-menu {
  font-size: 14px;
  margin-top: -5px;
}

.rs-picker-popup-daterange {
  margin-top: 10px;
}

.observation-tab-card {

  .observation-tab-card-content {
    padding-top: 24px;



    .observation-date-range-picker {
      margin-right: 10px;
    }

    .observation-select-type-container {

    }

    .table-actions {
      justify-content: center;
      align-items: center;
      gap: 8px;

      //.action-button {
      //  font-size: 14px;
      //  font-weight: 500;
      //  border-radius: 24px;
      //  padding: 8px 16px;
      //  transition: all 0.3s ease;
      //

      //
      //  &.verify {
      //    background: #4caf50;
      //    color: #fff;
      //    border: none;
      //
      //    &:hover {
      //      background: #43a047; // Darker shade on hover
      //    }
      //  }
      //}

      .action-button {
        background-color: #2DCE89;
        color: var.$color-white;
        padding: 5px 25px 5px 25px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 700;
        transition: all 0.3s ease;
        text-transform: capitalize;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        display: block ruby;
        width: fit-content;

        &.view-details {
          background: transparent;
          color: #4caf50;
          border: 1px solid #4caf50;

          &:hover {
            background: rgba(76, 175, 80, 0.1);
          }
        }

        &:hover {
          background-color: var.$color-white;
          color: #2DCE89;
        }

        &.active {
          background-color: var.$color-white;
          color: #2DCE89;
        }
      }
    }
  }
}
