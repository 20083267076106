@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.dialog-observation-detail-container {

  .dialog-observation-detail-header {
    padding-bottom: 1px;
    font-family: Lexend, sans-serif;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.800000011920929px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2DCE89;
    text-transform: capitalize;
    padding-left: 30px;
    margin-bottom: 5px;
    //padding-right: 50px;

    .probation-observation-result-container {
      border: dashed 1.7px #555;
      font-size: 16px;
      font-weight: 400;
      color: #555;
      margin-bottom: 10px;

      .probation-observation-result {
        margin: 5px;

        svg {
          margin: 7px 5px 5px 0;
        }

        .fail {
          font-size: 14px;
          color: #F5365C;
        }

        .success {
          font-size: 14px;
          color: #2DCE89;
        }

        h3 {
          font-size: 18px;
          font-weight: 700;
          color: #F5365C;
          line-height: 20px;
        }
      }
    }
  }

  .dialog-observation-detail-content {
    .table-container {
      min-width: 600px;
      padding: 0 15px 15px 15px;
      font-family: Arial, sans-serif;

      .sub-title {
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
      }

      .custom-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 30px;

        thead {
          background-color: #f0f0f0;

          th {
            text-align: center;
            padding: 10px;
            border: 1px solid #ddd;
          }
        }

        tbody {
          .group-header {
            font-weight: 700;
            text-align: center;
            background-color: #f9f9f9;

            td {
              vertical-align: top;
              border: 1px solid #ddd;
            }

            td:first-child {
              text-align: left;
            }

            td:last-child {
              border-left: 0 solid #ddd;
            }
          }

          .group-header-v2 {
            font-weight: 700;
            text-align: center;
            background-color: #f9f9f9;

            td {
              vertical-align: top;
              border: 1px solid #ddd;
              width: calc(100% / 3);
            }
          }

          .center-text {
            text-align: center;
          }

          .group-header-with-col3 {
            td {
              vertical-align: top;
              width: calc(100% / 3);
            }
          }

          tr {
            //&:nth-child(even) {
            //  background-color: #f9f9f9;
            //}

            td {
              padding: 10px;
              vertical-align: top;
              border: 1px solid #ddd;
            }

            .item-score {
              text-align: center;
              font-weight: 600;
            }
          }
        }
      }

      .extra-sections {
        margin-top: 20px;

        .section {
          margin-bottom: 20px;

          h3 {
            margin-bottom: 10px;
            font-size: 18px;
            color: #3b823b;
          }

          ul,
          ol {
            margin-left: 20px;
          }

          p {
            font-size: 14px;
            color: #555;
          }
        }
      }
    }
  }
}