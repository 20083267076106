@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.tab-workshop {
  background-color: white;
  border-radius: 12px;
  padding: 20px;

  &__container {
    &__content_container {
      background: red;
    }
  }

  &__group-tab {
    border-radius: func.pxToRem(12);
    padding: func.pxToRem(4);
    max-width: 420px;
  }

  .group-tab-button {
    &__title {
      font-weight: bold;
    }
  }

}

