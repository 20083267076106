$primary-color: #007bff;
$font-family: Arial, sans-serif;
$base-font-size: 16px;

$color-black: #000;
$color-white: #FFFFFF;
$color-gray: #6B778C;
$color-error: #F5365C;
$color-active-tab-button: white;

$breakpoints-md: 767.95px;
$breakpoints-lg: 768px;
$breakpoints-xl: 992px;
$breakpoints-xxl: 1200px;
$breakpoints-xxl1200: 1200px;
$breakpoints-xxl1366: 1366px;
$breakpoints-xxl1600: 1600px;

$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1366px,
        xxxl: 1921px,
);