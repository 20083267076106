@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;
@use "../../assets/style/mixins" as mixins;

.admin-mgr-link-container {
  top: 110px;
  margin: 0 auto;
  position: relative;

  @include mixins.breakpoint-down(md) {
    width: calc(100% - 40px);
  }

  @include mixins.breakpoint-up(md) {
    width: calc(100% - 80px);
  }

  @include mixins.breakpoint-up(lg) {
    width: calc(100% - 100px);
  }

  @include mixins.breakpoint-up(xl) {
    width: calc(100% - 120px);
  }

  @include mixins.breakpoint-up(xxl) {
    width: calc(100% - 150px);
  }

  .card-header {
    padding: 30px 30px 30px 30px;
  }

  .MuiCardHeader-title {
    text-transform: uppercase;
    font-family: Open Sans, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.4px;
    letter-spacing: -0.800000011920929px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 5px;
    color: #172B4D;
  }

  .MuiCardHeader-subheader {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #172B4D;
  }

  .table-mgr-link-container.ui-data-table-container {
    .page-number-container {
      .page-number-pagination {
        .MuiPagination-ul {
          .MuiPaginationItem-root {
            font-size: 18px;
            font-weight: 500;

            &.Mui-selected {
              background: linear-gradient(124deg, #5E72E4 -1.8%, #825EE4 105.23%);

              &:hover {
                background-color: #0056b3;
              }
            }
          }
        }
      }
    }
  }
}
