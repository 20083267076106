#apo {
  animation: jump1 0.5s ease-in-out forwards, jump2 0.5s ease-in-out 0.5s forwards,
    jump3 0.5s ease-in-out 1s forwards, slide 1s ease-in-out 1.5s forwards,
    drop 1s ease-in-out 2.5s forwards;
  top: 200px;
  right: 0;
  z-index: 9999;
}

#form {
  animation: form 1s ease-in-out 3s forwards;
  transform-origin: 0% 100%;
}

@media (max-width: 1600px) {
  #apo {
    animation: jump1 0.5s ease-in-out forwards, jump2 0.5s ease-in-out 0.5s forwards,
      jump3 0.5s ease-in-out 1s forwards, slide-small 1s ease-in-out 1.5s forwards,
      drop-small 1s ease-in-out 2.5s forwards;
    top: 200px;
    right: 0;
    z-index: 9999;
  }
}

#overlay {
  animation: overlay 1s ease-in-out 3s forwards;
  transform-origin: center;
  opacity: 1;
}

#apo-container {
  animation: container 1s ease-in-out 3s forwards;
}

@keyframes form {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes container {
  from {
    z-index: 9999;
  }
  to {
    z-index: -1;
  }
}

@keyframes overlay {
  from {
    opacity: 1;
    display: block;
  }
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes jump1 {
  0% {
    top: 200px;
    right: 0%;
  }

  6.67% {
    top: 160px;
    right: 1%;
  }
  13.33% {
    top: 130px;
    right: 2%;
  }
  20% {
    top: 110px;
    right: 3%;
  }
  26.67% {
    top: 95px;
    right: 4%;
  }
  33.33% {
    top: 85px;
    right: 5%;
  }
  40% {
    top: 80px;
    right: 6%;
  }
  46.67% {
    top: 80px;
    right: 7%;
  }
  53.33% {
    top: 85px;
    right: 8%;
  }
  60% {
    top: 95px;
    right: 9%;
  }
  66.67% {
    top: 110px;
    right: 10%;
  }
  73.33% {
    top: 130px;
    right: 11%;
  }
  80% {
    top: 160px;
    right: 12%;
  }
  86.67% {
    top: 190px;
    right: 13%;
  }
  93.33% {
    top: 200px;
    right: 14%;
  }
  100% {
    top: 200px;
    right: 15%;
  }
}

@keyframes jump2 {
  0% {
    top: 200px;
    right: 15%;
  }

  6.67% {
    top: 160px;
    right: 16%;
  }
  13.33% {
    top: 130px;
    right: 17%;
  }
  20% {
    top: 110px;
    right: 18%;
  }
  26.67% {
    top: 95px;
    right: 19%;
  }
  33.33% {
    top: 85px;
    right: 20%;
  }
  40% {
    top: 80px;
    right: 21%;
  }
  46.67% {
    top: 80px;
    right: 22%;
  }
  53.33% {
    top: 85px;
    right: 23%;
  }
  60% {
    top: 95px;
    right: 24%;
  }
  66.67% {
    top: 110px;
    right: 25%;
  }
  73.33% {
    top: 130px;
    right: 26%;
  }
  80% {
    top: 160px;
    right: 27%;
  }
  86.67% {
    top: 190px;
    right: 28%;
  }
  93.33% {
    top: 200px;
    right: 29%;
  }
  100% {
    top: 200px;
    right: 30%;
  }
}

@keyframes jump3 {
  0% {
    top: 200px;
    right: 30%;
  }

  6.67% {
    top: 160px;
    right: 31%;
  }
  13.33% {
    top: 130px;
    right: 32%;
  }
  20% {
    top: 110px;
    right: 33%;
  }
  26.67% {
    top: 95px;
    right: 34%;
  }
  33.33% {
    top: 85px;
    right: 35%;
  }
  40% {
    top: 80px;
    right: 36%;
  }
  46.67% {
    top: 80px;
    right: 37%;
  }
  53.33% {
    top: 85px;
    right: 38%;
  }
  60% {
    top: 95px;
    right: 39%;
  }
  66.67% {
    top: 110px;
    right: 40%;
  }
  73.33% {
    top: 130px;
    right: 41%;
  }
  80% {
    top: 160px;
    right: 42%;
  }
  86.67% {
    top: 190px;
    right: 43%;
  }
  93.33% {
    top: 200px;
    right: 44%;
  }
  100% {
    top: 200px;
    right: 45%;
  }
}

@keyframes slide {
  from {
    top: 200px;
    right: 45%;
  }
  to {
    top: 200px;
    right: calc(100% - 400px);
  }
}

@keyframes slide-small {
  from {
    top: 200px;
    right: 45%;
  }
  to {
    top: 200px;
    right: calc(100% - 300px);
  }
}

@keyframes drop {
  from {
    top: 200px;
    right: calc(100% - 400px);
  }
  to {
    top: calc(100% - 300px);
    right: calc(100% - 400px);
  }
}

@keyframes drop-small {
  from {
    top: 200px;
    right: calc(100% - 300px);
  }
  to {
    top: calc(100% - 250px);
    right: calc(100% - 300px);
  }
}
