@use "../../assets/style/functions" as func;
@use "../../assets/style/variables" as var;

.group-tab-button {
  flex: 1;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: func.pxToRem(35);
    border-radius: func.pxToRem(8);
    background: inherit;
    box-shadow: unset;
    cursor: pointer;

    // Active class
    &.active {
      background: white; // Active background
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    }

  }

  &__title {
    margin: 0;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    font-family: Lexend, serif;
    font-size: 12px;
    font-weight: 400;
    color: var.$color-black;
    line-height: 1.25;
  }
}
