@use "../../assets/style/variables" as var;
@use "../../assets/style/functions" as func;

.pin-change-form {
  .pin-change-field {
    margin-bottom: 15px;

    .pin-change-label {
      display: block;
      font-weight: 700;
      padding: 5px;
      font-size: 14px;
    }

    .pin-change-input {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;
      font-size: 14px;
      max-width: 500px;
      color: var.$color-black;
    }

    .pin-change-input.disabled {
      background-color: #B0B0B0;
      color: #8392AB;
      cursor: not-allowed;
      border: 0.5px solid #E2E8F0
    }

  }

  .pin-change-error {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #FFA2A2;
    font-size: 12px;
    margin: auto 15px;
  }

  .pin-change-success {
    color: green;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .pin-change-button {
    font-family: "Open Sans", sans-serif;
    padding: 6px 32px;
    background-color: #5E72E4;
    font-weight: 700;
    text-transform: uppercase;
    color: var.$color-white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    display: flex;

    .spinner-icon {
      margin: 5px;
    }
  }
}