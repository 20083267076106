@use "sass:map";

$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1366px,
        xxxl: 1921px,
);

@mixin breakpoint-up($key) {
  // Check if the breakpoint key exists in the map
  @if map.has_key($breakpoints, $key) {
    // Use map.get to retrieve the value of the specified key
    @media (min-width: #{map.get($breakpoints, $key)}) {
      @content; // Include the content inside the media query
    }
  } @else {
    // Throw an error if the key does not exist
    @error "Breakpoint `#{$key}` not found in the map.";
  }
}

@mixin breakpoint-down($key) {
  // Check if the breakpoint key exists in the map
  @if map.has_key($breakpoints, $key) {
    // Use map.get to retrieve the value and subtract 0.02px for max-width
    @media (max-width: #{map.get($breakpoints, $key) - 0.02}px) {
      @content; // Include the content inside the media query
    }
  } @else {
    // Throw an error if the key does not exist
    @error "Breakpoint `#{$key}` not found in the map.";
  }
}
